import React from 'react'
import Header from './header'
import {Link} from 'gatsby'

import '../styles/layout.css'
import styles from './layout.module.css'

const Layout = ({children, onHideNav, onShowNav, showNav, siteTitle}) => (
  <>
    <Header siteTitle={siteTitle} onHideNav={onHideNav} onShowNav={onShowNav} showNav={showNav} />
    <div className={styles.content}>{children}</div>
    <footer className={styles.footer}>
      <div className={styles.footerWrapper}>
        <div className={styles.siteInfo}>We occasionally link to goods offered by vendors to help the reader find relevant products. Some of these links may be affiliate in nature, meaning we earn small commissions if items are purchased. <Link to="/affiliate-disclosure">Affiliate Disclosure</Link></div>
        <div className={styles.footerNav}>
          <ul className={styles.footerNav_ul}>
            <li className={styles.footerNav_li}>
              <Link to="/about">About</Link>
            </li>
            <li className={styles.footerNav_li}>
              <Link to="/contact">Contact</Link>
            </li>
            <li className={styles.footerNav_li}>
              <Link to="/privacy">Privacy</Link>
            </li>
          </ul>

        </div>
        <div className={styles.siteInfo}>
          <Link to="/terms-of-use">Terms of Use</Link> &copy; {new Date().getFullYear()}, <a href='/'>Healthy Afternoon Snacks</a>
        </div>
      </div>
    </footer>
  </>
)

export default Layout
